/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import ReactGA from 'react-ga'

export const onClientEntry = () => {
  if (process.env.NODE_ENV !== 'development') {
    ReactGA.initialize('UA-128203016-1')
  }
}

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'development') {
    ReactGA.pageview(location.pathname)
  }
}
